import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Observer } from 'mobx-react-lite'
import qs from 'qs'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import AuthChecker from '../components/common/AuthChecker'

import userStore from '../store/UserStore'

import { Constants } from './../scripts/constants'
import Configs from '../scripts/configs'

const OauthRedirectPage = (props) => {
  return <Observer>{() => <AuthChecker children={<OauthRedirect {...props} />} />}</Observer>
}

const OauthRedirect = (props) => {
  const [authCode, setAuthCode] = useState(null)
  const [err, setErr] = useState(null)
  const [isCompleted, setIsCompleted] = useState(false)

  useEffect(() => {
    if (props.location.search) {
      const search = qs.parse(props.location.search.substring(1))
      if (search?.state && search?.state === userStore.currentUser.email) {
        setAuthCode(search?.code || null)
      } else {
        setErr(`Something Went Wrong While Setting Tokens. Please Try Again.`)
      }
    }
  }, [props.location.search])

  useEffect(() => {
    let timeoutId = undefined
    if (authCode) {
      ;(async () => {
        try {
          await axios.post(`${Configs.FirebaseFunctionUrl}/setGmailOauthTokens`, {
            code: authCode,
            userId: userStore.currentUser.id,
            email: userStore.currentUser.email,
          })
          setIsCompleted(true)
          timeoutId = setTimeout(() => {
            props.history.push(Constants.jobsConfigs.allPaths.Others.routes.Home.route)
          }, 2000)
        } catch (err) {
          if (axios.isAxiosError(err) && err.response) {
            console.error(err.response.data)
            setErr(err.response.data.message)
          } else {
            console.error(err)
            setErr(`Something Went Wrong While Setting Tokens. Please Try Again.`)
          }
        }
      })()
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [authCode, props.history])

  if (!authCode) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant='h4' align='center' gutterBottom>
          Error: no auth code found.
        </Typography>
        <Link
          to={Constants.jobsConfigs.allPaths.Others.routes.Home.route}
          style={{ textDecoration: 'none' }}>
          <Button variant='contained' color='primary'>
            Go To Home Page
          </Button>
        </Link>
      </div>
    )
  }

  if (err) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant='h4' align='center' gutterBottom>
          {err}
        </Typography>
        <Link
          to={Constants.jobsConfigs.allPaths.Others.routes.Home.route}
          style={{ textDecoration: 'none' }}>
          <Button variant='contained' color='primary'>
            Go To Home Page
          </Button>
        </Link>
      </div>
    )
  }

  return (
    <Observer>
      {() => (
        <>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            {isCompleted ? (
              <>
                <h3>Auth Completed Successfully. Now, You can send emails with your account.</h3>
                <h3>Redirecting to home page...</h3>
              </>
            ) : (
              <>
                <LinearProgress />
                <h3>Auth in progress...</h3>
                <h3>Please do not close this page.</h3>
              </>
            )}
          </div>
        </>
      )}
    </Observer>
  )
}

export default withRouter(OauthRedirectPage)
