import * as dayjs from 'dayjs'

import { firestore } from '../fire'

export const getSignatureData = (userId) => {
  return firestore.collection('emailSignatures').doc(userId).get()
}

export function editSignatureData(userId, newSigData) {
  return firestore
    .collection('emailSignatures')
    .doc(userId)
    .set(
      {
        ...newSigData,
        updatedAt: dayjs().unix(),
      },
      { merge: true }
    )
    .catch((err) => {
      console.error(`editSignatureData. Error:\n${err}`)
      throw new Error(err)
    })
}
