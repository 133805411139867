import React, { useState, useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'

import { showToast } from '../../scripts/localActions'
import { getSignatureData, editSignatureData, getTokenData } from '../../scripts/remoteActions'
import Configs from '../../scripts/configs'

import appStore from '../../store/AppStore'
import userStore from '../../store/UserStore'

const useStyles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.text.secondary,
  },
  Innerpaper: {
    width: '100%',
  },
}))

const ConfigureEmailSig = () => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [sendBrochure, setSendBrouchre] = useState(false)
  const [showEndNote, setShowEndNote] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasTokenData, setHasTokenData] = useState(false)

  useEffect(() => {
    getSignatureData(userStore.currentUser.id)
      .then((doc) => {
        if (doc.exists) {
          const { name, phone, address, sendBrochure, showEndNote } = doc.data()
          setName(name)
          setPhone(phone)
          setAddress(address)
          setSendBrouchre(sendBrochure)
          setShowEndNote(showEndNote)
        }
      })
      .catch((err) => {
        console.error(`Error getting signature data: ${err}`)
        showToast('Error getting signature data', 'error')
      })

    getTokenData(userStore.currentUser.id).then((doc) => {
      if (doc.exists) {
        const { gmailOauth } = doc.data()
        if (gmailOauth) {
          setHasTokenData(true)
        }
      }
    })
  }, [])

  const handleSignatureUpdate = async () => {
    try {
      setIsLoading(true)
      await editSignatureData(userStore.currentUser.id, {
        name: name.trim(),
        phone: phone.trim(),
        address: address.trim(),
        sendBrochure,
        showEndNote,
      })
      setIsLoading(false)
      showToast('Email signature updated successfully', 'success')
    } catch (err) {
      setIsLoading(false)
      console.error(`Error updating signature: ${err}`)
      showToast('Error updating Email signature', 'error')
    }
  }

  const handleResetOauthData = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to reset your Gmail Oauth data?')) {
        setIsLoading(true)
        const res = await axios.post(`${Configs.FirebaseFunctionUrl}/revokeGmailOauthTokens`, {
          userId: userStore.currentUser.id,
        })
        if (res.status === 200) {
          setHasTokenData(false)
        }
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      console.error(`Error removing token data: ${err}`)
      showToast('Error removing token data', 'error')
    }
  }

  const handleAddingOauthData = async () => {
    try {
      setIsLoading(true)
      showToast('Please wait while we redirect you to Gmail', 'info')
      const res = await axios.post(`${Configs.FirebaseFunctionUrl}/getOauthAuthorizationUrl`, {
        email: userStore.currentUser.email,
      })
      if (res.data.status === 'success') {
        window.location.href = res.data.authUrl
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.error(`Error adding token data: ${err}`)
      showToast('Error adding token data', 'error')
    }
  }

  return (
    <Paper className={classes.paper}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='visualization-content'
          id='visualization-header'>
          <Typography variant='h6' align='left' className='center-flex-row'>
            📧 Configure Email Signature
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper
            style={{ backgroundColor: appStore.darkMode ? '#303030' : '#fcfcfc' }}
            className={classes.Innerpaper}>
            <Scrollbars style={{ height: 300 }}>
              <Grid container spacing={4} style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={12} md={3}>
                  <div style={{ textAlign: 'center' }}>
                    <TextField
                      label='Name In Email'
                      value={name}
                      variant='outlined'
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div style={{ textAlign: 'center' }}>
                    <TextField
                      label='Phone In Email'
                      value={phone}
                      variant='outlined'
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div style={{ textAlign: 'center' }}>
                    <TextField
                      label='Address In Email'
                      value={address}
                      variant='outlined'
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div className='center-flex-column'>
                    <FormGroup column>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendBrochure}
                            onChange={() => setSendBrouchre((prev) => !prev)}
                          />
                        }
                        label='Send Brouchers In Email'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showEndNote}
                            onChange={() => setShowEndNote((prev) => !prev)}
                          />
                        }
                        label='Show Ending Note In Email'
                      />
                    </FormGroup>
                  </div>
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={isLoading}
                      onClick={handleSignatureUpdate}>
                      Update Email Signature
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'center' }}>
                    {hasTokenData ? (
                      <Button
                        variant='contained'
                        color='secondary'
                        disabled={isLoading}
                        onClick={handleResetOauthData}>
                        Reset Gmail Oauth Data
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={isLoading}
                        onClick={handleAddingOauthData}>
                        Add Gmail Oauth Data
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Scrollbars>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

export default ConfigureEmailSig
