// import * as dayjs from 'dayjs'

import { firestore } from '../fire'

export const getTokenData = (userId) => {
  return firestore.collection('tokens').doc(userId).get()
}

export function removeGmailOauthData(userId) {
  return firestore.collection('tokens').doc(userId).set(
    {
      gmailOauth: null,
    },
    { merge: true }
  )
}
