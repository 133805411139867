// import * as dayjs from 'dayjs'

import { firestore } from '../fire'

export function getAllSalesTrackSheets() {
  return firestore
    .collection('salesTrackSheets')
    .orderBy('lastUpdated', 'desc')
    .get()
    .catch((err) => {
      console.error(`getAllSalesTrackSheets. Error:\n${err}`)
      throw new Error(err)
    })
}

export function removeSalesTrackSheet(sheetId) {
  return firestore.collection('salesTrackSheets').doc(sheetId).delete()
}

export function editSheetTitle(sheetId, newTitle) {
  return firestore
    .collection('salesTrackSheets')
    .doc(sheetId)
    .set(
      {
        sheetTitle: newTitle,
      },
      { merge: true }
    )
    .catch((err) => {
      console.error(`editSheetTitle. Error:\n${err}`)
      throw new Error(err)
    })
}
