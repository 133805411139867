import React, { useState, useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import LinkIcon from '@material-ui/icons/Link'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RefreshIcon from '@material-ui/icons/Refresh'

import Configs from '../../scripts/configs'
import { showToast } from '../../scripts/localActions'
import {
  getAllSalesTrackSheets,
  getUserById,
  removeSalesTrackSheet,
  editSheetTitle,
  getUsers,
} from '../../scripts/remoteActions'

import appStore from '../../store/AppStore'

const useStyles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.text.secondary,
  },
  Innerpaper: {
    width: '100%',
  },
}))

const SalesTrackSheets = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [allSalesTrackSheetsData, setAllSalesTrackSheetsData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [refreshingSheets, setRefreshingSheets] = useState(false)
  const [addnewSheet, setAddnewSheet] = useState(false)
  const [editSheet, setEditSheet] = useState('')
  const [newSheetTitle, setNewSheetTitle] = useState('')

  useEffect(() => {
    setSalesTrackSheetsData()
  }, [])

  const setSalesTrackSheetsData = async () => {
    try {
      const snapshot = await getAllSalesTrackSheets()
      const allSheets = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      const allUsers = [...new Set(allSheets.map((item) => item.userId))]
      await Promise.all(
        allUsers.map(async (user) => {
          const userDoc = await getUserById(user)
          const userData = userDoc.exists ? userDoc.data() : {}
          allSheets.forEach((sheet) => {
            if (sheet.userId === user) {
              sheet.userData = userData
            }
          })
        })
      )
      setAllSalesTrackSheetsData(allSheets)
    } catch (err) {
      console.error(`Error in SalesTrackSheets useEffect:\n${err}`)
      showToast('Something went wrong while fetching sales track sheets', 'error')
    }
  }

  const handleSheetDelete = async (sheetName, sheetId) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Are you sure you want to delete ${sheetName}?`)) {
        setIsBusy(true)
        await removeSalesTrackSheet(sheetId)
        await setSalesTrackSheetsData()
        setIsBusy(false)
        showToast('Sales track sheet deleted successfully', 'success')
      }
    } catch (err) {
      setIsBusy(false)
      console.error(`Error in handleSheetDelete:\n${err}`)
      showToast('Something went wrong while deleting sales track sheet', 'error')
    }
  }

  const handleSheetTitleEdit = async (sheetId, newTitle) => {
    try {
      setIsBusy(true)
      const res = await axios.post(`${Configs.FirebaseFunctionUrl}/updateSpreadSheetTitle`, {
        sheetId,
        sheetTitle: newTitle.trim(),
      })
      if (res.status === 200) {
        await editSheetTitle(sheetId, newTitle.trim())
        await setSalesTrackSheetsData()
        setIsBusy(false)
        showToast('Sales track sheet title edited successfully', 'success')
        setEditSheet('')
        setNewSheetTitle('')
      } else {
        throw new Error('updateSpreadSheetTitle api err')
      }
    } catch (err) {
      setIsBusy(false)
      console.error(`Error in handleSheetTitleEdit:\n${err}`)
      showToast('Something went wrong while editing sales track sheet title', 'error')
    }
  }

  const handleSheetsRefresh = async () => {
    try {
      setRefreshingSheets(true)
      const res = await axios.post(`${Configs.FirebaseFunctionUrl}/updateSalesTrackSheetData`)
      if (res.status === 200) {
        setRefreshingSheets(false)
        showToast('Update request submitted successfully', 'success')
      } else {
        throw new Error('refreshSheets api err')
      }
    } catch (err) {
      setRefreshingSheets(false)
      console.error(`Error in handleSheetsRefresh:\n${err}`)
      showToast('Something went wrong while refreshing sales track sheets', 'error')
    }
  }

  return (
    <Paper className={classes.paper}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='visualization-content'
          id='visualization-header'>
          <Typography variant='h6' align='left' className='center-flex-row'>
            📄 Sales Track Sheets
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper
            style={{ backgroundColor: appStore.darkMode ? '#303030' : '#fcfcfc' }}
            className={classes.Innerpaper}>
            <Scrollbars style={{ height: 400 }}>
              {addnewSheet ? (
                <AddNewSheet
                  setAddnewSheet={setAddnewSheet}
                  setSalesTrackSheetsData={setSalesTrackSheetsData}
                />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '15px',
                      marginRight: '5px',
                      marginLeft: '5px',
                    }}>
                    <Tooltip title='Refresh Sheets Data' placement='bottom'>
                      <Button
                        variant='contained'
                        color='primary'
                        size='medium'
                        disabled={refreshingSheets}
                        onClick={handleSheetsRefresh}>
                        <RefreshIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title='Add New Sheet' placement='bottom'>
                      <Button
                        variant='contained'
                        color='secondary'
                        size='medium'
                        onClick={() => setAddnewSheet(true)}>
                        <AddCircleIcon />
                      </Button>
                    </Tooltip>
                  </div>
                  <br />
                  <Divider variant='fullWidth' style={{ marginBottom: '-8px' }} />
                  <List className={classes.root}>
                    {allSalesTrackSheetsData.length === 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '50px',
                          fontSize: '25px',
                          fontWeight: 'lighter',
                        }}>
                        No Sheet Created Yet.
                      </div>
                    ) : (
                      <>
                        {allSalesTrackSheetsData.map((sheet) => (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Tooltip title={sheet?.userData?.email} placement='bottom'>
                                    <Avatar
                                      alt={sheet?.userData?.name}
                                      src={sheet?.userData?.picUrl}
                                    />
                                  </Tooltip>
                                </ListItemAvatar>
                                {editSheet === sheet.sheetId ? (
                                  <TextField
                                    label='New Sheet Title'
                                    variant='outlined'
                                    value={newSheetTitle}
                                    onChange={(e) => setNewSheetTitle(e.target.value)}
                                  />
                                ) : (
                                  <ListItemText
                                    primary={sheet.sheetTitle}
                                    secondary={sheet?.userData?.nickname || sheet?.userData?.name}
                                  />
                                )}
                              </ListItem>
                              {editSheet === sheet.sheetId ? (
                                <ButtonGroup
                                  size='small'
                                  variant='text'
                                  orientation={isMobile ? 'vertical' : 'horizontal'}
                                  disableRipple
                                  disableFocusRipple
                                  disableElevation>
                                  <Button>
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      size='small'
                                      disabled={
                                        isBusy ||
                                        !newSheetTitle.trim() ||
                                        newSheetTitle.trim() === sheet.sheetTitle
                                      }
                                      onClick={() =>
                                        handleSheetTitleEdit(sheet.sheetId, newSheetTitle)
                                      }>
                                      <CheckIcon />
                                    </Button>
                                  </Button>
                                  <Button>
                                    <Button
                                      variant='contained'
                                      color='secondary'
                                      size='small'
                                      disabled={isBusy}
                                      onClick={() => {
                                        setEditSheet('')
                                        setNewSheetTitle('')
                                      }}>
                                      <CloseIcon />
                                    </Button>
                                  </Button>
                                </ButtonGroup>
                              ) : (
                                <>
                                  {!editSheet && (
                                    <ButtonGroup
                                      size='small'
                                      variant='text'
                                      orientation={isMobile ? 'vertical' : 'horizontal'}
                                      disableRipple
                                      disableFocusRipple
                                      disableElevation>
                                      <Button>
                                        <Button
                                          variant='contained'
                                          size='small'
                                          target='_blank'
                                          href={`https://docs.google.com/spreadsheets/d/${sheet.sheetId}`}>
                                          <LinkIcon />
                                        </Button>
                                      </Button>
                                      <Button>
                                        <Button
                                          variant='contained'
                                          color='primary'
                                          size='small'
                                          onClick={() => setEditSheet(sheet.sheetId)}>
                                          <EditIcon />
                                        </Button>
                                      </Button>
                                      <Button>
                                        <Button
                                          variant='contained'
                                          color='secondary'
                                          size='small'
                                          disabled={isBusy}
                                          onClick={() =>
                                            handleSheetDelete(sheet.sheetTitle, sheet.sheetId)
                                          }>
                                          <DeleteIcon />
                                        </Button>
                                      </Button>
                                    </ButtonGroup>
                                  )}
                                </>
                              )}
                            </div>
                            <Divider variant='fullWidth' component='li' />
                          </>
                        ))}
                      </>
                    )}
                  </List>
                </>
              )}
            </Scrollbars>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

const AddNewSheet = ({ setAddnewSheet, setSalesTrackSheetsData }) => {
  const [sheetTitle, setSheetTitle] = useState('')
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsersInfo] = useState([])
  const [email, setEmail] = useState('')
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    getUsers().then((snapshot) => {
      if (!snapshot.empty) {
        setUsers(snapshot.docs.map((doc) => doc.data()).filter((user) => user.isActive))
      }
    })
  }, [])

  useEffect(() => {
    if (users) {
      setAllUsersInfo(users)
    }
  }, [users])

  const handleSheetCreate = async () => {
    try {
      setIsBusy(true)
      showToast('Creating new sheet...', 'info')
      const forUser = allUsers.find((user) => user.email === email)
      if (!forUser) {
        showToast('User not found', 'error')
        return
      }
      const res = await axios.post(`${Configs.FirebaseFunctionUrl}/createSalesTrackSheet`, {
        sheetTitle: sheetTitle.trim(),
        forUser: forUser.uid,
      })
      if (res.status === 200) {
        await setSalesTrackSheetsData()
        showToast('Sheet created successfully', 'success')
        setAddnewSheet(false)
      }
    } catch (err) {
      setIsBusy(false)
      console.log('err: ', err)
      showToast('Something went wrong while creating new sheet', 'error')
    }
  }

  return (
    <div style={{ margin: '20px' }}>
      <h4>Sheet Title:</h4>
      <TextField
        value={sheetTitle}
        autoFocus={true}
        variant='outlined'
        onChange={(e) => setSheetTitle(e.target.value)}
      />
      <br />
      <h4>For User:</h4>
      <Autocomplete
        options={allUsers}
        onInputChange={(e, val, res) => {
          console.log('res: ', res)
          if (res === 'clear') {
            setEmail('')
          }
          if (res === 'reset') {
            setEmail((val.split(' (')[1] || '').split(')')[0])
          }
        }}
        oncl
        loading={!users}
        getOptionLabel={(option) => `${option.name} (${option.email})`}
        renderInput={(params) => (
          <TextField {...params} style={{ maxWidth: '400px' }} variant='outlined' />
        )}
      />
      <br />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}>
        <Button
          variant='contained'
          color='secondary'
          size='medium'
          disabled={isBusy || sheetTitle.trim() === '' || email.trim() === ''}
          style={{ marginRight: 5 }}
          onClick={handleSheetCreate}>
          Create Sheet
        </Button>
        <Button
          size='medium'
          disabled={isBusy}
          style={{ marginTop: 10 }}
          onClick={() => setAddnewSheet(false)}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default SalesTrackSheets
